import React, { useState, useEffect, useRef } from 'react';
import './DocumentViewer.css';
import config from '../config';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const DocumentViewer = ({ selectedDocument, selectedCompany }) => {
  const [query, setQuery] = useState('');
  const [responses, setResponses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [iframeLoaded, setIframeLoaded] = useState(false); //Added for search currently not connected to backend
  const iframeRef = useRef();
  const [selectedDocuments, setSelectedDocuments] = useState([
    { id: 'doc1', name: 'Form 10-K, 2024' },
    { id: 'doc2', name: 'Form 8-K' },
    { id: 'doc3', name: 'Form S-1' }
  ]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('Facts');
  const [factsData, setFactsData] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const [notes, setNotes] = useState([]);
  const [activeNoteId, setActiveNoteId] = useState(null);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const availableDocuments = [
    { id: 'doc4', name: 'Form 10-Q' },
    { id: 'doc5', name: 'Form 8-K (Supplemental)' },
    { id: 'doc6', name: 'Form 10-K, 2023' }
  ];

  // Fetch Facts JSON data
  // Fetch Stats JSON data
  useEffect(() => {
    if (activeTab === 'Facts') {
      loadJsonData('facts', selectedCompany, setFactsData);
    } else if (activeTab === 'Stats') {
      loadJsonData('stats', selectedCompany, setStatsData);
    }
  }, [activeTab, selectedCompany]);

  const loadJsonData = (type, selectedCompany, setter) => {
      fetch(`${config.apiUrl}/static/${selectedCompany.toLowerCase()}/${type}_data.json`)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then(data => {
          if (data.error) {
            throw new Error(`File not found`);
          }

          setter(data);
        })
        .catch(error => {
          console.error(`Failed to load ${type} data:`, error);
          setter(null);
        });
  };

  // Load notes from localStorage or initialize
  useEffect(() => {
    const savedNotes = JSON.parse(localStorage.getItem('notes')) || [];
    setNotes(savedNotes);
    if (savedNotes.length > 0) {
      setActiveNoteId(savedNotes[0].id);
    }
  }, []);

  // Save notes to localStorage when notes change
  useEffect(() => {
    localStorage.setItem('notes', JSON.stringify(notes));
  }, [notes]);

  const renderTable = (data, headers) => {
    const getCellClass = (header, value) => {
      if (header === 'change' || header.includes('growth')) {
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
          return numValue > 0 ? 'positive' : numValue < 0 ? 'negative' : '';
        }
      }
      return '';
    };
  
    return (
      <table>
        <thead>
          <tr>{headers.map(header => <th key={header}>{header}</th>)}</tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              {headers.map(header => (
                <td key={header} className={getCellClass(header, row[header])}>
                  {row[header]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const handleQuerySubmit = async (e) => {
    e.preventDefault();
    if (query.trim()) {
      setResponses((prevResponses) => [
        ...prevResponses,
        `Query: ${query}`,
        'Response: •'
      ]);
      setQuery('');

      let dotCount = 1;
      const dotInterval = setInterval(() => {
        setResponses((prevResponses) => {
          const updatedResponses = [...prevResponses];
          updatedResponses[updatedResponses.length - 1] = 
            `Response: ${'•'.repeat((dotCount % 3) + 1)}`; 
          return updatedResponses;
        });
        dotCount++;
      }, 350)

      const data = { question: query };
      const response = await fetch(`${config.apiUrl}/query`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      const answer = response.ok 
        ? (await response.json()).answer 
        : "Oops something went wrong";

      clearInterval(dotInterval);

      setResponses((prevResponses) => [
        ...prevResponses.slice(0, -1),
        `Response: ${DOMPurify.sanitize(marked.parse(answer))}` // Combined into single response
      ]);
    }
  };

  const removeDocument = (docId) => {
    setSelectedDocuments(selectedDocuments.filter(doc => doc.id !== docId));
  };

  const addDocument = (doc) => {
    if (!selectedDocuments.find(d => d.id === doc.id)) {
      setSelectedDocuments([...selectedDocuments, doc]);
      setIsDropdownOpen(false);
    }
  };

// Update handleSearch function
// Currently not working as it should

const handleSearch = async () => {
  if (!searchTerm.trim()) return;

  try {
    const response = await fetch(`${config.apiUrl}/api/search`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        file_path: `${selectedCompany.toLowerCase()}/latest.html`,
        term: searchTerm,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      console.log(data.matches);
    } else {
      console.error("Search failed");
    }
  } catch (error) {
    console.error("Search error:", error);
  }
};


const handleAddNote = () => {
  const newNote = {
    id: Date.now(),
    title: 'Untitled',
    content: ''
  };
  setNotes([newNote, ...notes]);
  setActiveNoteId(newNote.id);
};

const handleDeleteNote = (noteId) => {
  setNotes(notes.filter(note => note.id !== noteId));
  if (activeNoteId === noteId) {
    setActiveNoteId(notes.length > 1 ? notes[0].id : null);
  }
};

const handleNoteSelect = (noteId) => {
  setActiveNoteId(noteId);
};

const handleNoteChange = (field, value) => {
  setNotes(
    notes.map(note =>
      note.id === activeNoteId ? { ...note, [field]: value } : note
    )
  );
};

const toggleSidebar = () => {
  setSidebarCollapsed(!sidebarCollapsed);
};

const activeNote = notes.find(note => note.id === activeNoteId);

return (
  <div className="grid-container">
    {/* Document viewer with search bar */}
    <div className="document-viewer">
      {/* Commenting out search bar for now
      <div className="search-bar">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search..."
          className="search-input"
        />
        <button onClick={handleSearch} className="search-button">
          🔍
        </button>
      </div>
      */}
      <iframe
        ref={iframeRef}
        src={`${config.apiUrl}/static/${selectedCompany.toLowerCase()}/latest.html`}
        title="Document Viewer"
        className="document-iframe"
        sandbox="allow-same-origin allow-scripts"
        onLoad={() => setIframeLoaded(true)}
      ></iframe>
      </div>

      {/* Facts, Stats, and Notebook Tabs in the top right */}
      <div className="facts-container">
        <div className="tab-navigation">
          <button onClick={() => setActiveTab('Facts')} className={activeTab === 'Facts' ? 'active' : ''}>Facts</button>
          <button onClick={() => setActiveTab('Stats')} className={activeTab === 'Stats' ? 'active' : ''}>Stats</button>
          <button onClick={() => setActiveTab('Notebook')} className={activeTab === 'Notebook' ? 'active' : ''}>Notebook</button>
        </div>

        <div className="modular-content">
          {activeTab === 'Facts' && factsData && (
            <div className="modular-boxes">
              {factsData.sections.map((section, index) => (
                <div key={index} className="modular-box">
                  <h3>{section.title}</h3>
                  <ul className="bullet-points">
                    {section.content.map((point, i) => (
                      <li key={i}>{point}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          )}

          {activeTab === 'Stats' && statsData && (
            <div className="stats-section">
              <h3>Product Sales by Category</h3>
              {renderTable(statsData.productSales, ["category", "2024", "change", "2023", "2022"])}

              <h3>Segment Operating Performance</h3>
              {renderTable(statsData.segmentPerformance, ["region", "2024", "change", "2023", "2022"])}

              <h3>Shareholder Equity and Paid-in Capital</h3>
              {renderTable(statsData.shareholderEquity, ["category", "2024", "2023", "2022"])}

              <h3>Stock Performance Comparison</h3>
              {renderTable(statsData.stockPerformance, ["year", "apple", "sp500", "dj_tech"])}
            </div>
          )}

          {activeTab === 'Notebook' && (
            <div className="notebook">
              <div className={`notes-list ${sidebarCollapsed ? 'collapsed' : ''}`}>
                <div className="notes-list-header">
                  <button className="toggle-sidebar-btn" onClick={toggleSidebar}>
                    {sidebarCollapsed ? '>' : '<'}
                  </button>
                  {!sidebarCollapsed && (
                    <button className="add-note-btn" onClick={handleAddNote}>+</button>
                  )}
                </div>
                <div className="notes-list-content">
                  {notes.map(note => (
                    <div
                      key={note.id}
                      className={`note-item ${note.id === activeNoteId ? 'active' : ''}`}
                      onClick={() => handleNoteSelect(note.id)}
                    >
                      <span className="note-title">{note.title}</span>
                      <button
                        className="delete-note-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteNote(note.id);
                        }}
                      >
                        ×
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="note-editor">
                {activeNote && (
                  <>
                    <input
                      type="text"
                      className="note-title-input"
                      value={activeNote.title}
                      onChange={(e) => handleNoteChange('title', e.target.value)}
                      placeholder="Note title"
                    />
                    <textarea
                      className="note-content-input"
                      value={activeNote.content}
                      onChange={(e) => handleNoteChange('content', e.target.value)}
                      placeholder="Start writing..."
                    ></textarea>
                  </>
                )}
              </div>
            </div>

          )}
        </div>
      </div>

      {/* Workflow box in the bottom left */}
      <div className="workflow-box">
        <h3>Selected Documents</h3>
        {selectedDocuments.map((doc) => (
          <div key={doc.id} className="workflow-item">
            <input type="checkbox" id={doc.id} name="document" value={doc.name} />
            <label htmlFor={doc.id}>{doc.name}</label>
            <button className="remove-btn" onClick={() => removeDocument(doc.id)}>×</button>
          </div>
        ))}
        <button className="add-document-btn" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
          Add document
        </button>
        {isDropdownOpen && (
          <div className="document-dropdown">
            {availableDocuments.map((doc) => (
              <div 
                key={doc.id} 
                className="document-dropdown-item"
                onClick={() => addDocument(doc)}
              >
                {doc.name}
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Query box in the bottom right */}
      <div className="query-box">
        <div className="query-output">
          {responses.map((response, index) => (
            <div 
              key={index}
              className="response-item"
              dangerouslySetInnerHTML={{ __html: response }}>
            </div>
          ))}
        </div>
        <form onSubmit={handleQuerySubmit} className="query-form">
          <input
            type="text"
            placeholder="Query"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="query-input"
          />
          <button type="submit" className="query-submit-btn">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default DocumentViewer;
